<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form ref="form" :model="form" label-width="130px" style="overflow-x:hidden;overflow-y:auto;">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="描述" prop="userAccount">
            <el-input v-model="form.desc" placeholder="" auto-complete="off"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="类型">
            <vxe-select v-model="form.sceneCode" placeholder="请选择" style="width:100%;">
              <vxe-option
                v-for="(item, index) in codeList"
                :key="index"
                :label="item.name"
                :value="item.sceneCode"
              />
            </vxe-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="图片" prop="healthReport">
            <upload-img :limit-size="0.5" :limit="1" :upload-lists="form.pictureInfoList" @uploadChange="uploadSuccess"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center;" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
// import CustomDrawer from '@/components/CustomDrawer/index'
// import CustomForm from '@/components/CustomForm/index'
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg'
// import CustomFuzzySearch from '@/components/CustomFuzzySearch/index'

export default {
  name: 'Form',
  components: { UploadImg },
  data() {
    return {
      codeList: [{ name: '首页轮播图', sceneCode: 'homePage' }, { name: '活动中心轮播图', sceneCode: 'activityPage' }],
      title: '',
      formId: '',
      titleName: '',
      formStatus: '',
      photoUploadLists: [],
      formModel: {
        photoId: '',
        newsId: ''
      },
      form: {
        desc: '',
        id: '',
        pictureInfoList: [],
        pictures: '',
        sceneCode: ''
      }
    }
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['swiperDetail', 'swiperUpdate', 'swiperAdd']),
    newsFuzzySearchData(param, cb) {
      param['hasTenant'] = true
      this.newsPublicPageList(param).then(res => {
        cb(res)
      }).catch(() => {
      })
    },
    handleAdd() {
      this.init('add')
    },
    handleEdit(id) {
      this.init('edit', id)
    },
    uploadSuccess(data) {
      this.form.pictures = data.join(',')
    },
    initView() {
      if (this.$route.params.type === 'edit') {
        this.swiperDetail({ id: this.$route.params.id }).then((res) => {
          if (res.code === 200) {
            this.form = res.data
          }
        })
      }
    },
    submitEvent() {
      const server = this.$route.params.type == 'add' ? 'swiperAdd' : 'swiperUpdate'
      this[server](this.form).then((res) => {
        if (res.code == 200) {
          this.$XModal.message({
            message: this.routeType == 'add' ? '添加成功' : '修改成功',
            status: 'success'
          })
          this.$router.go(-1)
        }
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.vxe-select--panel {
  z-index: 9999999 !important;
}
</style>
